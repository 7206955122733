import PageSection from "components/PageSection";
import { Body3, Small, Title2Alt } from "components/Typography";

const jobs = [
    {
        title: "Finance Lead - Project Finance",
        subtitle: "Finance • Remote • United Kingdom • Full time",
        link: "https://blog.inrange.io/finance-lead"
    }
];

const JobItem = ({ job }) => (
    <a href={job.link} target="_blank" rel="noreferrer">
        <div className="fade-in-scroll flex items-center justify-between w-full border-1 border-primary rounded-md px-8 py-2">
            <div className="flex-1">
                <Body3>{job?.title}</Body3>
                <Small>{job?.subtitle}</Small>
                {job?.sallaryRange && <small>{job.sallaryRange}</small>}
            </div>
            <svg
                className="-rotate-90"
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.41 0.579956L6 5.16996L10.59 0.579956L12 1.99996L6 7.99996L0 1.99996L1.41 0.579956Z"
                    className="fill-primary"
                />
            </svg>
        </div>
    </a>
);

const CareersList = () => {
    return (
        <PageSection className="relative z-10 bg-white" containerClass="py-16 md:pb-24 lg:py-32" id="careers-list">
            <div className="lg:w-1/2 mb-24">
                <Title2Alt className="fade-in-scroll mb-8">Join us in helping to create a sustainable future</Title2Alt>
                <Small className="fade-in-scroll">Find the right job for you.</Small>
            </div>
            {jobs?.length && (
                <div className="grid gap-4">
                    {jobs.map((job, index) => (
                        <JobItem key={job.title + `${index}`} job={job} />
                    ))}
                </div>
            )}
        </PageSection>
    );
};

export default CareersList;
